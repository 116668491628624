import * as Types from '../../apiTypes';

import { gql } from '@apollo/client';
export type RetailerPreviewFragment = { __typename?: 'Retailer', id: string, name: string, status: Types.RetailerStatus, type: Types.RetailerType, region: Types.Region };

export type RetailerDetailFragment = { __typename?: 'Retailer', key: string, priority?: number | null, priceUpdate?: string | null, discount?: number | null, mapStatus: Types.RetailerMapStatus, onlineOrderingState: boolean, id: string, name: string, status: Types.RetailerStatus, type: Types.RetailerType, region: Types.Region };

export const RetailerPreviewFragmentDoc = gql`
    fragment RetailerPreview on Retailer {
  id
  name
  status
  type
  region
}
    `;
export const RetailerDetailFragmentDoc = gql`
    fragment RetailerDetail on Retailer {
  ...RetailerPreview
  key
  priority
  priceUpdate
  discount
  mapStatus
  onlineOrderingState
}
    ${RetailerPreviewFragmentDoc}`;